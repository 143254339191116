import dynamic from 'next/dynamic';
import React from 'react';

import { BUTTON_ACTIONS } from 'holded/lib/actions';
import { ButtonType } from 'holded/modules/cms/domain/components/link';
import { LinkSmoothScroll } from 'holded/modules/cms/ui/shared/components/LinkSmoothScroll';

import styles from './button.module.scss';

const HeroIcon = dynamic(() => import('holded/modules/cms/ui/shared/components/HeroIcon'));
const NextImage = dynamic(() => import('holded/modules/cms/ui/shared/components/Image'));

type Props = {
  children: React.ReactNode;
  extraClass?: string;
  data: ButtonType;
  cb?: () => void;
  iconOptions?: {
    position?: string;
    outline?: boolean;
    size?: number;
  };
};
const BUTTON_TYPE: Record<string, string> = {
  primary:
    'inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-blue hover:opacity-80',
  secondary:
    'inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-blue bg-white hover:opacity-80',
  dark: 'inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-gray-hard hover:opacity-80',
  soft: 'inline-flex items-center justify-center px-4 py-2 border border-gray-soft rounded-md shadow-sm text-base font-medium text-gray-hard bg-white hover:opacity-80',
  alternative: `${styles.button} inline-flex items-center justify-center px-4 py-2 rounded-md shadow-sm text-base font-medium text-blue bg-white`,
  default:
    'inline-flex items-center justify-center px-4 py-2 border border-transparent text-base font-medium text-blue hover:opacity-80',
};

const DEFAULT_ICON_OPTIONS = { position: 'left', outline: true, size: 6 };

const Button = ({ data, children, extraClass = '', iconOptions = DEFAULT_ICON_OPTIONS, cb }: Props) => {
  const iconMargin = iconOptions.position == 'right' ? 'ml-3' : 'mr-3';
  const href = (data?.url?.slice(-1) == '/' ? data?.url?.slice(0, -1) : data?.url ?? '').toLowerCase();
  return (
    <>
      {data?.action !== 'default' && data?.action !== null ? (
        <>
          {data?.action === 'cb' && cb && (
            <button
              id={data?.idTag ?? undefined}
              className={`cursor-pointer ${BUTTON_TYPE[data?.type]} ${extraClass}`}
              onClick={() => BUTTON_ACTIONS.cb(cb)}
              {...(data?.dataRef && { 'data-ref': data.dataRef })}
            >
              {iconOptions.position == 'right' && <span>{children}</span>}
              {data?.image?.data ? (
                <NextImage media={data.image.data} classImg={'h-6 w-6 mr-2'} />
              ) : (
                <>
                  {data?.icon && (
                    <HeroIcon
                      icon={data?.icon}
                      outline={iconOptions.outline}
                      size={iconOptions.size}
                      extraClass={iconMargin}
                    />
                  )}
                </>
              )}
              {iconOptions.position == 'left' && <span>{children}</span>}
            </button>
          )}
          {data?.action == 'intercom' && (
            <button
              id={data?.idTag ?? undefined}
              className={`cursor-pointer ${BUTTON_TYPE[data?.type]} ${extraClass}`}
              onClick={BUTTON_ACTIONS.intercom}
              {...(data?.dataRef && { 'data-ref': data.dataRef })}
            >
              {iconOptions.position == 'right' && <span>{children}</span>}
              {data?.image?.data ? (
                <NextImage media={data.image.data} classImg={'h-6 w-6 mr-2'} />
              ) : (
                <>
                  {data?.icon && (
                    <HeroIcon
                      icon={data?.icon}
                      outline={iconOptions.outline}
                      size={iconOptions.size}
                      extraClass={iconMargin}
                    />
                  )}
                </>
              )}
              {iconOptions.position == 'left' && <span>{children}</span>}
            </button>
          )}
          {data?.action == 'goTo' && (
            <LinkSmoothScroll href={href}>
              <button
                id={data?.idTag ?? undefined}
                className={`cursor-pointer ${BUTTON_TYPE[data?.type]} ${extraClass}`}
                {...(data?.dataRef && { 'data-ref': data.dataRef })}
              >
                {iconOptions.position == 'right' && <span>{children}</span>}
                {data?.image?.data ? (
                  <NextImage media={data.image.data} classImg={'h-6 w-6 mr-2'} />
                ) : (
                  <>
                    {data?.icon && (
                      <HeroIcon
                        icon={data?.icon}
                        outline={iconOptions.outline}
                        size={iconOptions.size}
                        extraClass={iconMargin}
                      />
                    )}
                  </>
                )}
                {iconOptions.position == 'left' && <span>{children}</span>}
              </button>
            </LinkSmoothScroll>
          )}
        </>
      ) : (
        <a
          id={data?.idTag ?? undefined}
          role="button"
          href={href}
          target={data.newTab ? '_blank' : '_self'}
          rel="noreferrer"
          className={`${BUTTON_TYPE[data?.type]} ${extraClass} `}
          {...(data?.dataRef && { 'data-ref': data.dataRef })}
        >
          <span className={`cursor-pointer flex items-center`}>
            {iconOptions.position == 'right' && <span>{children}</span>}
            {data?.image?.data ? (
              <NextImage media={data.image.data} classImg={'h-6 w-6 mr-2'} />
            ) : (
              <>
                {data?.icon && (
                  <HeroIcon
                    icon={data?.icon}
                    outline={iconOptions.outline}
                    size={iconOptions.size}
                    extraClass={iconMargin}
                  />
                )}
              </>
            )}
            {iconOptions.position == 'left' && <span>{children}</span>}
          </span>
        </a>
      )}
    </>
  );
};

Button.defaultProps = {};

export default Button;
